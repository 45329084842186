<template>
  <div class="d-flex flex-row justify-space-between">
    <div
      v-for="answer in answers"
      :key="answer.id"
      class="custom-btn-group"
      :class="setSelectedClass(answer.id)"
    >
      <VBtn
        data-test="tt-btn"
        class="pa-0"
        width="24"
        :min-width="styleBtnWidth"
        height="54"
        depressed
        :ripple="$vuetify.breakpoint.lgAndUp"
        @click="handleValue(answer.id)"
      >
        {{ answer.text }}
      </VBtn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QuestionNPS',

  props: {
    answers: {
      type: Array,
      required: true,
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      activeAnswerId: null,
    };
  },

  computed: {
    styleBtnWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
        case 'lg':
        case 'md':
          return 60;
        case 'sm':
        case 'xs':
        default:
          return 24;
      }
    },
  },

  created() {
    this.activeAnswerId = this.userAnswer.answerId;
  },

  methods: {
    handleValue(id) {
      if (this.activeAnswerId === id) {
        this.activeAnswerId = null;
        this.$emit('delete-answer');
        return;
      }

      this.activeAnswerId = id;
      this.$emit('answer', {
        answerId: id,
      });
    },
    setSelectedClass(answerId) {
      if (!this.activeAnswerId) return '';
      return answerId === this.activeAnswerId ? 'selected' : 'not-selected';
    },
  },
};
</script>

<style scoped lang="scss">
.custom-btn-group  {
  padding: 0;
  text-align: center;

  .v-btn {
    font-size: 20px;
    font-weight: 500;
  }
}

.custom-btn-group.not-selected {
  .v-btn {
    color: map-get($tt-black, 'lighten-3');
  }
}

.custom-btn-group.selected {
  .v-btn {
    background-color: map-get($tt-blue, 'base') !important;
    color: map-get($tt-black, 'lighten-7');
  }
}
</style>
