<template>
  <div>
    <template
      v-if="multipleAnswers"
    >
      <VCheckbox
        v-for="(item) in answers"
        :key="item.id"
        v-model="checkboxModel"
        :value="item.id"
        hide-details
        :disabled="checkCheckbox(item.id)"
        off-icon="$checkboxOffLight"
        :label="item.text"
        class="mt-0 mb-5"
        @click="handleCheckboxModelValue"
      />
      <VCheckbox
        v-if="hasOtherValue"
        v-model="otherOption.selected"
        :label="otherStatement"
        off-icon="$checkboxOffLight"
        :disabled="checkCheckbox(otherOption)"
      />
    </template>
    <template
      v-else
    >
      <div
        v-for="answer in answers"
        :key="answer.id"
        class="d-flex mb-7 cursor-pointer"
        @click="handleRadioModelValue(answer.id)"
      >
        <VBtn
          data-test="tt-btn"
          outlined
          fab
          color="tt-blue"
          width="24"
          height="24"
          class="mr-2"
          :ripple="$vuetify.breakpoint.lgAndUp"
        >
          <VIcon
            v-show="answer.id === radioModel"
            color="tt-blue"
            size="8"
          >
            $circleSolid
          </VIcon>
        </VBtn>
        <span>
          {{ answer.text }}
        </span>
      </div>
      <div
        v-if="hasOtherValue"
        class="d-flex mb-7 cursor-pointer"
        @click="toggleOtherValue"
      >
        <VBtn
          data-test="tt-btn"
          outlined
          fab
          color="tt-blue"
          width="24"
          height="24"
          class="mr-2"
          :ripple="$vuetify.breakpoint.lgAndUp"
        >
          <VIcon
            v-show="otherOption.selected"
            color="tt-blue"
            size="8"
          >
            $circleSolid
          </VIcon>
        </VBtn>
        <span>
          {{ otherStatement }}
        </span>
      </div>
    </template>
    <div
      v-if="otherOption.selected"
      class="mb-3"
    >
      <p class="mb-3">
        Напишите свой вариант
      </p>
      <VTextarea
        v-model.trim="textFieldModel"
        class="custom-textarea"
        solo
        flat
        hide-details
        background-color="tt-black lighten-6"
        placeholder="Текст"
      />
    </div>
    <div
      v-if="commentable"
      class="mb-3"
    >
      <h2 class="text-h6 mb-6 font-weight-medium">
        {{ $t('commentOnYourChoice') }}
      </h2>
      <VTextarea
        v-model.trim="commentText"
        class="custom-textarea"
        no-resize
        solo
        flat
        hide-details
        height="96"
        placeholder="Текст"
        background-color="tt-black lighten-6"
        @input="debouncedCommentHandler"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'QuestionList',
  props: {
    answers: {
      type: Array,
      default: () => ([]),
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
    maxAnswers: {
      type: Number,
      default: null,
    },
    otherStatement: {
      type: String,
      default: '',
    },
    commentable: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkboxModel: [],
      radioModel: null,
      textFieldModel: '',
      otherOption: {
        text: this.otherStatement,
        selected: false,
        value: this.otherStatement,
      },
      commentText: '',
    };
  },
  computed: {
    multipleAnswers() {
      return Boolean(this.maxAnswers > 1);
    },
    hasOtherValue() {
      return Boolean(this.otherStatement);
    },
  },
  watch: {
    otherOption: {
      handler(val) {
        if (this.multipleAnswers
          && !this.checkboxModel.length
          && !val.selected
          && !this.textFieldModel.length) return;
        if (!val.selected) this.textFieldModel = '';
        if (!this.multipleAnswers && val.selected) {
          if (this.radioModel !== null) {
            this.$emit('delete-answer');
            this.radioModel = null;
          }
        }
      },
      deep: true,
    },
    textFieldModel() {
      this.debouncedTextModelHandler();
    },
  },
  created() {
    this.debouncedTextModelHandler = debounce(this.handleTextModelValue, 250);
    this.debouncedCommentHandler = debounce(this.commentHandler, 250);
    if (Object.keys(this.userAnswer).length) {
      this.textFieldModel = this.userAnswer.valueText || '';
      if (this.multipleAnswers) {
        this.checkboxModel = this.userAnswer.answerIds || [];
      }
      if (!this.multipleAnswers) {
        this.radioModel = this.userAnswer.answerId;
      }
      if (this.hasOtherValue && this.textFieldModel.length) {
        this.otherOption.selected = true;
      }
    }
    if (this.comment?.length) {
      this.commentText = this.comment;
    }
  },
  methods: {
    /**
     * @param id {number} по умолчанию
     * @param id {object} в случае с otherOption
     */
    checkCheckbox(id) {
      if (id.selected) return false;
      const maxAnswersValue = !this.otherOption.selected ? this.maxAnswers : this.maxAnswers - 1;
      return !this.checkboxModel.includes(id) && this.checkboxModel.length >= maxAnswersValue;
    },
    handleRadioModelValue(id) {
      if (this.textFieldModel.length) {
        this.textFieldModel = '';
      }
      if (id === this.radioModel) {
        this.radioModel = null;
        this.$emit('delete-answer');
        return;
      }
      if (this.otherOption.selected) this.otherOption.selected = false;
      this.radioModel = id;

      const payload = {
        answerId: this.radioModel,
      };

      if (this.commentText.length) payload.comment = this.commentText;

      this.$emit('answer', payload);
    },
    handleCheckboxModelValue() {
      if (!this.checkboxModel.length && !this.otherOption.selected) {
        this.$emit('delete-answer');
        return;
      }

      const payload = {
        answerIds: this.checkboxModel,
      };

      if (this.commentText.length) payload.comment = this.commentText;

      if (this.textFieldModel.length) payload.valueText = this.textFieldModel;

      this.$emit('answer', payload);
    },
    handleTextModelValue() {
      if (this.textFieldModel === this.userAnswer.valueText) return;
      const payload = {
        valueText: this.textFieldModel,
      };

      if (this.multipleAnswers) {
        payload.answerIds = this.checkboxModel;

        if (!this.textFieldModel.length) {
          this.$emit('delete-answer-with-id', this.checkboxModel.length);
          return;
        }
      }

      if (!this.multipleAnswers) {
        if (!this.textFieldModel.length && this.radioModel === null) {
          this.$emit('delete-answer-with-id');
          return;
        }
      }
      this.$emit('answer', payload);
    },
    commentHandler() {
      this.$emit('send-comment', this.commentText);
    },
    toggleOtherValue() {
      this.otherOption.selected = !this.otherOption.selected;
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .v-icon {
    color: #00A0F2;
    caret-color: #00A0F2;
  }
  ::v-deep .v-messages[role="alert"] {
    color: #ef323f !important;
    caret-color: #ef323f !important;
  }
  ::v-deep .v-label {
    font-size: 16px !important;
    line-height: 1.75 !important;
    font-weight: 400 !important;
    color: #0B1218 !important;
  }
  .custom-textarea {
    border: 1px solid map-get($tt-black, 'lighten-5') !important;
    border-radius: 5px !important;
  }
  ::v-deep .v-btn--outlined {
    border-width: 2px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
