<!-- eslint-disable vue/no-v-html -->
<template>
  <VRow>
    <VCol>
      <div
        class="mb-2 text-h5 custom-title text-break"
        v-html="title"
      />
      <div class="tt-black--text text--lighten-2 hint text-break">
        {{ hint }}
      </div>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'QuestionTitle',

  props: {
    title: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .hint {
    font-size: 14px;
    letter-spacing: 0.61px;
    line-height: 19px;
  }
</style>
