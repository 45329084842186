<template>
  <VRow>
    <VCol>
      <VRow
        v-if="title"
        class="tt-black--text text--lighten-2"
      >
        {{ title }}
      </VRow>

      <VRow v-if="hint">
        {{ hint }}
      </VRow>

      <Component
        :is="matchQuestionTypesComponents[type]"
        :answers="answers"
        :user-answer="userAnswer"
        :left-title="left"
        :right-title="right"
        :other-statement="otherStatement"
        :max-answers="maxAnswers"
        :include-if-not-answered="includeIfNotAnswered"
        :visualization-type="visualizationType"
        :question-title="questionTitle"
        :commentable="questionCommentable"
        :comment="questionComment"
        @answer="updateAnswer"
        @delete-answer="deleteAnswer"
        @send-comment="sendComment"
        @delete-answer-with-id="deleteAnswerWithId"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapMutations } from 'vuex';
import { requestsSentCount } from '@/plugins/vuex/mutationTypes';
import QuestionNPS from '@/components/questions/QuestionNPS.vue';
import QuestionOpen from '@/components/questions/QuestionOpen.vue';
import QuestionDichotomy5 from '@/components/questions/QuestionDichotomy5.vue';
import QuestionDichotomy7 from '@/components/questions/QuestionDichotomy7.vue';
import QuestionLikert6 from '@/components/questions/QuestionLikert6.vue';
import QuestionLikert7 from '@/components/questions/QuestionLikert7.vue';
import QuestionDichotomyWithPolarAffirmation from '@/components/questions/QuestionDichotomyWithPolarAffirmation.vue';
import QuestionList from '@/components/questions/QuestionList.vue';
import QuestionScale from '@/components/questions/QuestionScale.vue';
import QuestionHorizontal from '@/components/questions/QuestionHorizontal.vue';
import { matchQuestionTypesComponents } from '@/utils/constants';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { sessionId } from '@/services/cookies';

export default {
  name: 'QuestionAnswer',
  components: {
    QuestionNPS,
    QuestionOpen,
    QuestionDichotomy5,
    QuestionDichotomy7,
    QuestionLikert6,
    QuestionLikert7,
    QuestionDichotomyWithPolarAffirmation,
    QuestionList,
    QuestionScale,
    QuestionHorizontal,
  },
  inject: ['Names'],

  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
    userSurveySlug: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      matchQuestionTypesComponents,
      otherAnswerId: null,
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    type() {
      return this.question.type;
    },
    answers() {
      return this.question.answers;
    },
    questionId() {
      return this.question.id;
    },
    left() {
      return this.question.leftTitle;
    },
    right() {
      return this.question.rightTitle;
    },
    otherStatement() {
      return this.question.otherStatement;
    },
    required() {
      return this.question.required;
    },
    maxAnswers() {
      return this.question.maxAnswers;
    },
    includeIfNotAnswered() {
      return this.question.includeIfNotAnswered;
    },
    visualizationType() {
      return this.question.visualizationType;
    },
    questionTitle() {
      return this.question.title;
    },
    questionCommentable() {
      return this.question.commentable;
    },
    questionComment() {
      return this.question?.comment?.body || '';
    },
    sessionId() {
      return sessionId.get();
    },
  },

  created() {
    if (this.userAnswer.otherAnswer && this.userAnswer.answerId === null) {
      this.otherAnswerId = this.userAnswer.id;
    }
  },

  methods: {
    ...mapMutations([requestsSentCount]),
    async updateAnswer(payload) {
      const {
        answerId, answerIds, valueText, notAnswered, comment,
      } = payload;
      const { questionId } = this;
      this[requestsSentCount](1);
      try {
        const { data } = await apiService.answerUpdate(this.userSurveySlug, {
          questionId,
          answerId,
          valueText,
          answerIds,
          notAnswered,
          comment,
        }, {
          headers: { 'x-session-id': this.sessionId },
        });

        if (data?.userAnswers?.length) {
          const otherAnswerElement = data.userAnswers.find((answer) => answer.otherAnswer && !answer.answerId);
          if (otherAnswerElement) {
            this.otherAnswerId = otherAnswerElement.id;
          }
        }

        if (data?.userAnswer) {
          if (data.userAnswer?.answerId === null && data.userAnswer?.id) {
            this.otherAnswerId = data.userAnswer.id;
          }
        }

        if (this.required) {
          this.$emit('update-required', questionId);
        }
      } catch (err) {
        if (err.response.data.message === 'survey_already_finished') {
          this.$router.replace({
            name: this.Names.R_USER_SURVEY_FINISHED,
            params: { slug: this.userSurveySlug },
          });
          return;
        }
        errorHandler(err);
      } finally {
        this[requestsSentCount](-1);
      }
    },
    async deleteAnswer() {
      const { questionId } = this;
      this[requestsSentCount](1);
      try {
        await apiService.answerDelete(this.userSurveySlug, { questionId });
        if (this.required) {
          this.$emit('remove-required', questionId);
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        this[requestsSentCount](-1);
      }
    },
    async deleteAnswerWithId(answersLength) {
      const { userSurveySlug, questionId, otherAnswerId: id } = this;
      const payload = { userSurveySlug, questionId };
      this[requestsSentCount](1);
      try {
        await apiService.answerWithIdDelete(id, payload);
        if (this.required && !answersLength) {
          this.$emit('remove-required', questionId);
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        this[requestsSentCount](-1);
      }
    },
    async sendComment(comment) {
      const { slug, questionId } = this;

      const payload = {
        user_survey_slug: slug,
        question_id: questionId,
        body: comment,
      };
      this[requestsSentCount](1);
      try {
        await apiService.questionCommentPost({
          params: payload,
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        this[requestsSentCount](-1);
      }
    },
  },
};
</script>
