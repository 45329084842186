const smileList = [
  {
    value: '1',
    icon: '$angryLight',
    color: 'tt-red',
  },
  {
    value: '2',
    icon: '$frownLight',
    color: 'tt-orange',
  },
  {
    value: '3',
    icon: '$mehLight',
    color: 'tt-yellow',
  },
  {
    value: '4',
    icon: '$smileLight',
    color: 'tt-blue',
  },
  {
    value: '5',
    icon: '$grinBeamLight',
    color: 'tt-green lighten-2',
  },
  {
    value: '6',
    icon: '$laughLight',
    color: 'tt-green lighten-1',
  },
  {
    value: '7',
    icon: '$grinHeartsLight',
    color: 'tt-green',
  },
];

export default smileList;
