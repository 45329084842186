<template>
  <div>
    <AppHeader
      :languages="languages"
      :survey-state-page="surveyStatePage"
      :anonymity="anonymity"
      @nav-back="navBackwardOnSurvey"
    />

    <TTView v-if="blocks">
      <VRow
        v-for="(block, idx) in blocks"
        :key="block.id"
        class="flex-column"
      >
        <template v-if="blockTypes.question.includes(block.renderType)">
          <VCol
            v-for="question in block.questions"
            :key="question.id"
          >
            <VRow v-if="matchQuestionTypesComponents[question.type]">
              <VCol
                data-test="question"
                :data-test-value="`${question.id}`"
              >
                <VRow justify="center">
                  <VCol
                    lg="6"
                    md="6"
                    sm="10"
                  >
                    <VImg :src="question.imageUrl" />
                  </VCol>
                </VRow>

                <template v-if="question.required">
                  <VChip
                    v-if="isRequiredQuestionNotAnswered(question.id)"
                    class="mb-2 required-warn"
                    color="#FFE2E2"
                    text-color="#EF323F"
                    small
                  >
                    {{ $t('requiredQuestion') }}
                  </VChip>
                  <VChip
                    v-else
                    class="mb-2"
                    color="#F5F7F8"
                    text-color="#63676B"
                    small
                  >
                    {{ $t('requiredQuestion') }}
                  </VChip>
                </template>

                <QuestionTitle
                  data-test="question-title"
                  :title="question.title"
                  :hint="question.hint"
                />

                <QuestionAnswer
                  data-test="question-answers"
                  :question="question"
                  :user-answer="userAnswer(question.id)"
                  :user-survey-slug="userSurveySlug"
                  @update-required="updateRequired"
                  @remove-required="addToRequired"
                />

                <VDivider
                  v-if="idx !== blocks.length - 1"
                  class="mt-8"
                />
              </VCol>
            </VRow>
          </VCol>
        </template>
        <template v-else-if="blockTypes.block.includes(block.renderType)">
          <QuestionBlock
            :data-test-value="`block_id_${block.id}`"
            :block="block"
            :user-answers="userAnswers"
            @update-required="updateRequired"
            @remove-required="addToRequired"
          />
        </template>
      </VRow>

      <VRow>
        <VCol>
          <ActionsSurvey
            :is-last-page="isLastPage"
            :is-greeting-page="isGreetingPage"
            @move="validateAndNavForwardOnSurvey"
          />
        </VCol>
      </VRow>
      <VSnackbar
        v-if="isTestingSurvey"
        timeout="-1"
        top
        centered
        outlined
        color="#EBECED"
        content-class="tt-black--text"
        :value="true"
      >
        <VIcon
          color="tt-red"
          size="20"
          class="mr-2"
        >
          $exclamationCircle
        </VIcon>
        <span class="text-body-1 font-weight-medium">
          {{ $t('testingSnackBarTitle') }}
        </span>
      </VSnackbar>

      <VSnackbar
        v-if="requiredQuestionsValidation"
        timeout="10000"
        top
        centered
        outlined
        color="#EBECED"
        content-class="tt-black--text"
        :value="true"
      >
        <VIcon
          color="tt-red"
          size="20"
          class="mr-2"
        >
          $exclamationCircle
        </VIcon>
        <span class="text-body-1 font-weight-medium">
          {{ $t('requiredQuestionWarn') }}
        </span>
      </VSnackbar>
    </TTView>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import * as apiService from '@/services/api';
import {
  MOVE_TYPES,
  UserSurveyStatus,
  matchQuestionTypesComponents,
  SurveyStatus,
  blockTypes,
  matchBlockTypesComponents,
} from '@/utils/constants';
import { toggleLoading, updateProgressBar } from '@/plugins/vuex/mutationTypes';
import QuestionTitle from '@/components/app/QuestionTitle.vue';
import QuestionAnswer from '@/components/app/QuestionAnswer.vue';
import ActionsSurvey from '@/views/UI/ActionsSurvey.vue';
import TTView from '@/components/TTView.vue';
import AppHeader from '@/components/app/AppHeader.vue';
import errorHandler from '@/helpers/errorHandler';
import QuestionBlock from '@/components/app/QuestionBlock.vue';

export default {
  name: 'SurveyPage',

  components: {
    TTView,
    QuestionTitle,
    QuestionAnswer,
    ActionsSurvey,
    AppHeader,
    QuestionBlock,
  },
  inject: ['Names'],

  data() {
    return {
      languages: [],
      surveyCurrentLanguage: '',
      blocks: null,
      orgstructurePageOrder: 0,
      userAnswers: null,
      orgstructureRequired: false,
      totalPages: 0,
      surveyStatePage: 1,
      anonymity: true,
      matchQuestionTypesComponents,
      surveyName: '',
      requiredQuestionsArr: [],
      requiredQuestionsValidation: false,
      blockTypes,
      matchBlockTypesComponents,
    };
  },

  computed: {
    userSurveySlug() {
      return this.$route.params.slug;
    },
    progress() {
      return Math.ceil((this.surveyStatePage / this.totalPages) * 100);
    },
    isFirstPage() {
      return this.surveyStatePage === 1;
    },
    nextPageIsOrgstructure() {
      return this.surveyStatePage === this.orgstructurePageOrder;
    },
    isLastPage() {
      return this.surveyStatePage === this.totalPages;
    },
    isGreetingPage() {
      const checkBlockIsNotStaticCB = (block) => block.renderType !== 'text' && block.renderType !== 'image';
      const someBlockIsNotStatic = this.blocks.find(checkBlockIsNotStaticCB);
      return this.isFirstPage && !someBlockIsNotStatic;
    },
    nextButtonDisabled() {
      return this.requiredQuestionsArr.length !== 0;
    },
    isTestingSurvey() {
      return Boolean(this.$route.query.testing);
    },
  },

  watch: {
    '$i18n.locale': {
      async handler(val) {
        if (val !== this.surveyCurrentLanguage) {
          this.surveyCurrentLanguage = val;
          await this.getPageNumber();
        }
      },
    },
  },

  async created() {
    await this.initSessionSurvey();
  },

  methods: {
    ...mapMutations([updateProgressBar]),
    ...mapMutations([toggleLoading]),
    async initSessionSurvey() {
      this[toggleLoading](true);

      try {
        const postResponse = await apiService.surveySessionWelcomeGet(this.userSurveySlug);
        const {
          anonymity, status, page, surveyName, surveyStatus, orgstructureRequired, orgstructurePageOrder,
        } = postResponse.data;
        this.orgstructureRequired = orgstructureRequired;
        this.surveyStatePage = page;
        this.anonymity = anonymity;
        this.orgstructurePageOrder = orgstructurePageOrder;
        this.surveyName = surveyName;

        this[updateProgressBar]({ surveyName });

        if (surveyStatus === SurveyStatus.FINISHED) {
          this.$router.push({ name: this.Names.R_USER_SURVEY_FINISHED });
          return;
        }

        if (status === UserSurveyStatus.DONE) {
          this.$router.push({ name: this.Names.R_USER_SURVEY_END });
          return;
        }

        if (status === UserSurveyStatus.IN_PROGRESS) {
          await this.getAvailableLanguages();
          await this.getUserSurveyCurrentLanguage();
          await this.getPageNumber();
          this.checkRequiredQuestions();
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    async getAvailableLanguages() {
      this[toggleLoading](true);
      try {
        const { data } = await apiService.surveyLanguagesGet(this.userSurveySlug);
        this.languages = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    async getUserSurveyCurrentLanguage() {
      this[toggleLoading](true);
      try {
        const {
          data: { language },
        } = await apiService.surveyCurrentContextGet({ params: { user_survey_slug: this.userSurveySlug } });
        this.surveyCurrentLanguage = language;
        this.$i18n.locale = this.surveyCurrentLanguage;
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    async getPageNumber() {
      try {
        const response = await apiService.surveyPageNumberGet(this.userSurveySlug, this.surveyStatePage);
        const {
          anonymity, blocks, totalPages, userAnswers,
        } = response.data;
        this.blocks = blocks;
        this.anonymity = anonymity;
        this.totalPages = totalPages;
        this.userAnswers = userAnswers;

        this[updateProgressBar]({
          surveyName: this.surveyName,
          progress: this.progress,
        });
      } catch (err) {
        errorHandler(err);
      }
    },
    async navForwardOnSurvey() {
      this[toggleLoading](true);
      window.scrollTo(0, 0);

      try {
        const query = this.isTestingSurvey ? { testing: true } : {};
        if (this.nextPageIsOrgstructure && this.orgstructureRequired) {
          this.$router.push({
            name: this.Names.R_SURVEY_ORGSTRUCTURE,
            query,
          });
          return;
        }
        const postResponse = await apiService.surveyMove(this.userSurveySlug, {
          moveType: MOVE_TYPES.FORWARD,
        });
        const { page, status } = postResponse.data;
        this.surveyStatePage = page;

        if (status === UserSurveyStatus.DONE) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_END,
          });
        }

        if (status === UserSurveyStatus.IN_PROGRESS) {
          await this.getPageNumber();
          this.checkRequiredQuestions();
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    async navBackwardOnSurvey() {
      this[toggleLoading](true);

      try {
        const postResponse = await apiService.surveyMove(this.userSurveySlug, {
          moveType: MOVE_TYPES.BACKWARD,
        });
        const { page } = postResponse.data;
        this.surveyStatePage = page;
        await this.getPageNumber();
        this.checkRequiredQuestions();
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    userAnswer(questionId) {
      const answersArr = this.userAnswers.filter((answers) => answers.questionId === questionId);
      const [userAnswer] = answersArr;

      if (answersArr.length > 1) {
        const findedOtherAnswer = answersArr.find((answer) => answer.otherAnswer);
        if (findedOtherAnswer) {
          userAnswer.otherAnswer = findedOtherAnswer.otherAnswer;
          userAnswer.valueText = findedOtherAnswer.valueText;
          userAnswer.id = findedOtherAnswer.id;
        }
        userAnswer.answerIds = answersArr.map((answer) => answer.answerId).filter((id) => id !== null);
        return userAnswer;
      }
      if (userAnswer) {
        userAnswer.answerIds = userAnswer.answerId ? [userAnswer.answerId] : [];
      }
      return userAnswer;
    },
    checkRequiredQuestions() {
      if (this.requiredQuestionsArr.length) this.requiredQuestionsArr = [];

      this.blocks.forEach((block) => {
        const questions = block.questions.filter((question) => question.required);
        if (questions.length) {
          const questionsIds = questions.map((question) => question.id);
          this.addRequiredQuestion(questionsIds);
        }
      });

      if (this.userAnswers.length) {
        this.userAnswers.forEach((answer) => {
          if (this.requiredQuestionsArr.includes(answer.questionId)) {
            this.removeRequiredQuestion(answer.questionId);
          }
        });
      }
    },
    addRequiredQuestion(questionIds) {
      if (Array.isArray(questionIds)) {
        this.requiredQuestionsArr = [...new Set([...this.requiredQuestionsArr, ...questionIds])];
      } else {
        this.requiredQuestionsArr.push(questionIds);
      }
      this.requiredQuestionsValidation = false;
    },
    removeRequiredQuestion(questionId) {
      const questionIndex = this.requiredQuestionsArr.findIndex((qId) => qId === questionId);
      this.requiredQuestionsArr.splice(questionIndex, 1);
    },
    updateRequired(questionId) {
      if (this.requiredQuestionsArr.includes(questionId)) {
        this.removeRequiredQuestion(questionId);
        this.requiredQuestionsValidation = false;
      }
    },
    addToRequired(questionId) {
      this.addRequiredQuestion(questionId);
    },
    isRequiredQuestionNotAnswered(questionId) {
      return this.requiredQuestionsArr.includes(questionId) && this.requiredQuestionsValidation;
    },
    validateAndNavForwardOnSurvey() {
      if (this.requiredQuestionsArr.length) {
        this.requiredQuestionsValidation = true;
        this.scrollToFirstRequiredQuestion();
      } else {
        this.navForwardOnSurvey();
      }
    },
    scrollToFirstRequiredQuestion() {
      this.$nextTick(() => {
        document.querySelector('.required-warn').scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
    },
  },
};
</script>
