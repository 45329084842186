<template>
  <VTextarea
    id="comment"
    v-model.trim="valueText"
    class="custom-textarea mt-2"
    no-resize
    solo
    flat
    hide-details
    :placeholder="$t('writeComment')"
    background-color="tt-black lighten-6"
    data-test="comment-area"
    @blur="handleValue"
  />
</template>

<script>
export default {
  name: 'QuestionOpen',

  props: {
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      valueText: '',
      prevValueText: '',
    };
  },

  created() {
    const { valueText } = this.userAnswer;
    if (valueText) {
      this.valueText = valueText; this.prevValueText = valueText;
    }
  },

  methods: {
    handleValue() {
      if (this.prevValueText !== this.valueText) {
        this.prevValueText = this.valueText;
        this.$emit('answer', {
          valueText: this.valueText,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-textarea {
  border: 1px solid map-get($tt-black, 'lighten-5') !important;
  border-radius: 5px !important;
}
</style>
