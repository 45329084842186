<template>
  <div>
    <VRow
      v-if="leftTitle || rightTitle"
      class="side-titles align-end"
    >
      <VCol
        v-if="leftTitle"
        :class="{ 'inactive' : activeIdx > 3 }"
        lg="5"
      >
        <div class="text-break">
          {{ leftTitle }}
        </div>
      </VCol>

      <VSpacer
        v-if="$vuetify.breakpoint.smAndUp"
      />

      <VCol
        v-if="rightTitle"
        lg="5"
        class="text-end"
        :class="{ 'inactive' : activeIdx !== -1 && activeIdx < 3 }"
      >
        <div class="text-break">
          {{ rightTitle }}
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="d-flex justify-space-between pt-0">
        <div class="custom-divider pt-3" />
        <div class="custom-divider pt-3" />
      </VCol>
    </VRow>
    <VRow class="flex-nowrap px-3">
      <VCol
        v-for="(answer, idx) in answers"
        :key="answer.id"
        class="custom-btn-group"
        :class="[
          {
            'text-left' : idx === 0,
            'text-end' : idx === answers.length - 1
          },
          setSelectedClass(answer.id)]"
      >
        <VBtn
          depressed
          data-test="tt-btn"
          width="40"
          :min-width="styleBtnWidth"
          :min-height="54"
          @click="handleValue(answer.id, idx)"
        >
          <VIcon :size="styleDot(idx)">
            $circleSolid
          </VIcon>
        </VBtn>
        <div
          v-if="activeAnswerId ? isActive(answer.id) : defaultShownTitlesIdx.includes(idx)"
          :class="styleMarginsOnMobile(idx)"
          class="text-break subtitle pt-2"
        >
          {{ answer.text }}
        </div>
      </VCol>
    </VRow>
  </div>
</template>

<script>

export default {
  name: 'QuestionDichotomyWithPolarAffirmation',

  props: {
    answers: {
      type: Array,
      required: true,
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
    leftTitle: {
      type: String,
      default: '',
    },
    rightTitle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      defaultShownTitlesIdx: [0, 3, 6],
      activeAnswerId: null,
      activeIdx: null,
    };
  },

  computed: {
    styleBtnWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
        case 'lg':
        case 'md':
          return 100;
        case 'sm':
        case 'xs':
        default:
          return 40;
      }
    },
  },

  created() {
    this.activeAnswerId = this.userAnswer.answerId;
    this.activeIdx = this.answers.findIndex((item) => item.id === this.activeAnswerId);
  },

  methods: {
    isActive(answerId) {
      return answerId === this.activeAnswerId;
    },
    handleValue(id, idx) {
      if (this.activeAnswerId === id) {
        this.activeAnswerId = null;
        this.activeIdx = -1;
        this.$emit('delete-answer');
        return;
      }

      this.activeAnswerId = id;
      this.activeIdx = idx;
      this.$emit('answer', {
        answerId: id,
      });
    },
    setSelectedClass(answerId) {
      if (!this.activeAnswerId) return '';
      return answerId === this.activeAnswerId ? 'selected' : 'not-selected';
    },
    styleDot(idx) {
      return [14, 10, 6, 4, 6, 10, 14][idx];
    },
    styleMarginsOnMobile(idx) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return {
          'mr-n14': idx === 0,
          'mx-n6': !this.defaultShownTitlesIdx.includes(idx),
          'ml-n14': idx === this.answers.length - 1,
        };
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.custom-btn-group  {
  line-height: 19px;
  letter-spacing: 0.61px;
  padding: 0;
  text-align: center;

  .v-btn {
    .v-btn__content {
      font-size: 20px;
      letter-spacing: -0.345455px;
      line-height: 25px;
      font-weight: 500;
    }
  }

  .subtitle {
    font-size: 14px;
    color: map-get($tt-black, 'lighten-2') !important;
  }
}

.custom-btn-group.not-selected {
  .v-btn {
    .v-btn__content {
      .v-icon {
        color: map-get($tt-black, 'lighten-3') !important;
      }
    }
  }

  .subtitle {
    display: none;
  }
}

.custom-btn-group.selected {
  .v-btn {
    background-color: map-get($tt-blue, 'base') !important;
    color: map-get($tt-black, 'lighten-7');
  }

  .subtitle {
    color: map-get($tt-black, 'base') !important;
  }
}

.side-titles {
  font-size: 14px;
  letter-spacing: 0.61px;
  line-height: 19px;

  .inactive {
    color: map-get($tt-black, 'lighten-2');
  }
}
.custom-divider {
  border-top: 1px solid map-get($tt-black, 'lighten-4');
  border-right: 1px solid map-get($tt-black, 'lighten-4');
  border-left: 1px solid map-get($tt-black, 'lighten-4');
  max-width: 42%;
  width: 100%;
}
</style>
