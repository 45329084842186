<template>
  <div>
    <VRow
      no-gutters
      justify="space-between"
      class="mb-6"
      :class="{ 'flex-nowrap' : desktopBreakpoints }"
    >
      <VCol
        :cols="12"
        class="d-flex flex-nowrap align-start justify-space-between custom-col"
        :class="{ 'mb-6' : !desktopBreakpoints }"
      >
        <div
          v-for="(answer, i) in answers"
          :key="`answer-${answer.id}`"
          class="d-flex flex-column flex-wrap custom-item"
          :class="[textAligment(i), itemAligment(i)]"
          :style="{ width : `${itemWidth}%` }"
        >
          <VBtn
            data-test="tt-btn"
            fab
            :width="desktopBreakpoints ? 36 : 24"
            :height="desktopBreakpoints ? 36 : 24"
            depressed
            class="mb-2"
            :ripple="$vuetify.breakpoint.lgAndUp"
            :color="answer.id === currentAnswerId ? 'tt-blue' : 'tt-black lighten-6'"
            :class="answer.id === currentAnswerId ? 'tt-white--text' : 'tt-black--text'"
            @click="handleAnswer(answer)"
          >
            {{ answer.score }}
          </VBtn>
          <p
            v-show="titleVision(answer, i)"
            class="mb-0 tt-black--text text--lighten-2"
          >
            {{ answer.text }}
          </p>
        </div>
        <VDivider
          class="custom-divider"
          :style="{
            width : (!includeIfNotAnswered || !desktopBreakpoints) ? '100%' : `calc(100% - ${itemWidth}% - 24px)`
          }"
        />
        <div
          v-if="includeIfNotAnswered && desktopBreakpoints"
          class="text-center"
          :style="{ width : `${itemWidth}%` }"
          :class="{
            'col-4' : !desktopBreakpoints,
            'ml-6' : desktopBreakpoints
          }"
        >
          <VBtn
            data-test="tt-btn"
            fab
            width="36"
            height="36"
            depressed
            :ripple="$vuetify.breakpoint.lgAndUp"
            :color="cantRateOption.id === currentAnswerId ? 'tt-red' : 'tt-black lighten-6'"
            :class="cantRateOption.id === currentAnswerId ? 'tt-white--text' : 'tt-black--text'"
            class="mb-2"
            @click="handleAnswer(cantRateOption)"
          >
            <VIcon
              size="20"
            >
              $close
            </VIcon>
          </VBtn>
          <p class="mb-0 tt-black--text text--lighten-2">
            {{ cantRateOption.title }}
          </p>
        </div>
      </VCol>
      <VCol
        v-if="includeIfNotAnswered && !desktopBreakpoints"
        cols="12"
      >
        <VBtn
          data-test="tt-btn"
          fab
          width="32"
          height="32"
          depressed
          :ripple="$vuetify.breakpoint.lgAndUp"
          :color="cantRateOption.id === currentAnswerId ? 'tt-red' : 'tt-black lighten-6'"
          :class="cantRateOption.id === currentAnswerId ? 'tt-white--text' : 'tt-black--text'"
          @click="handleAnswer(cantRateOption)"
        >
          <VIcon
            size="16"
          >
            $close
          </VIcon>
        </VBtn>
        <span class="ml-2 tt-black--text text--lighten-2">
          {{ cantRateOption.title }}
        </span>
      </VCol>
    </VRow>
    <VRow v-if="commentable">
      <VCol cols="12">
        <h2 class="text-h6 mb-6 font-weight-medium">
          {{ $t('commentOnYourChoice') }}
        </h2>
        <VTextarea
          v-model.trim="commentText"
          class="custom-textarea"
          no-resize
          solo
          flat
          hide-details
          height="96"
          :placeholder="$t('text')"
          background-color="tt-black lighten-6"
          @input="debouncedCommentHandler"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'QuestionScale',
  props: {
    answers: {
      type: Array,
      default: () => ([]),
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
    includeIfNotAnswered: {
      type: Boolean,
      default: false,
    },
    commentable: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentAnswerId: null,
      cantRateOption: {
        title: this.$t('cantRate'),
        id: 'notAnswered',
      },
      commentText: '',
    };
  },
  computed: {
    itemWidth() {
      const width = Math.round(100 / this.answers.length);
      if (Number.isFinite(width)) return width;
      return 100;
    },
    desktopBreakpoints() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  created() {
    this.debouncedCommentHandler = debounce(this.commentHandler, 250);
    if (Object.keys(this.userAnswer).length) {
      this.currentAnswerId = this.userAnswer.answerId;
      if (this.userAnswer.answerId === null && this.userAnswer.notAnswered) {
        this.currentAnswerId = this.cantRateOption.id;
      }
    }
    if (this.comment?.length) {
      this.commentText = this.comment;
    }
  },
  methods: {
    handleAnswer(answer) {
      if (answer.id === this.currentAnswerId) {
        this.currentAnswerId = null;
        this.$emit('delete-answer');
        return;
      }
      this.currentAnswerId = answer.id;
      const id = this.currentAnswerId;
      const payload = {};
      // Если выбран вариант "Не могу ответить", то ничего не отправляем
      if (id === 'notAnswered') {
        payload.notAnswered = true;
      } else {
        payload.answerId = id;
      }
      if (this.commentText.length) payload.comment = this.commentText;
      this.$emit('answer', payload);
    },
    textAligment(i) {
      if (i === 0) return 'text-left';
      if (i === this.answers.length - 1) return 'text-right';
      return 'text-center';
    },
    itemAligment(i) {
      if (i === 0) return 'align-start';
      if (i === this.answers.length - 1) return 'align-end';
      return 'align-center';
    },
    titleVision(answer, index) {
      if (!this.currentAnswerId) return index === 0 || index === this.answers.length - 1;
      return answer.id === this.currentAnswerId;
    },
    commentHandler() {
      this.$emit('send-comment', this.commentText);
    },
  },
};
</script>

<style scoped lang="scss">
  .custom-col {
    position: relative;
  }
  .custom-item {
    position: relative;
    z-index: 2;
    word-break: break-word;
    @media screen and (min-width: 1264px) {
      min-width: 45px;
    }
  }
  .custom-divider {
    width: 100%;
    border-width: 2px;
    position: absolute;
    top: 11px;
    z-index: 1;
    @media screen and (min-width: 1264px) {
      top: 18px;
    }
  }
</style>
