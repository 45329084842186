<template>
  <VRow class="flex-nowrap px-3">
    <VCol
      v-for="(answer, idx) in answers"
      :key="answer.id"
      class="custom-btn-group-smile"
      :class="[
        {
          'text-left' : idx === 0,
          'text-end' : idx === answers.length - 1
        },
        setSelectedClass(answer.id)]"
    >
      <VBtn
        :ripple="false"
        data-test="tt-btn"
        depressed
        width="40"
        :min-width="styleBtnWidth"
        :min-height="54"
        :color="getBtnBackgroundColor(answer.id, idx)"
        @click="handleValue(answer.id)"
      >
        <VIcon :color="styleIcon[idx].color">
          {{ styleIcon[idx].icon }}
        </VIcon>
      </VBtn>
      <div
        v-if="activeAnswerId ? isActive(answer.id) : defaultShownTitlesIdx.includes(idx)"
        :class="styleMarginsOnMobile(idx)"
        class="text-break subtitle pt-2"
      >
        {{ answer.text }}
      </div>
    </VCol>
  </VRow>
</template>

<script>

export default {
  name: 'QuestionDichotomy7',

  props: {
    answers: {
      type: Array,
      required: true,
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      defaultShownTitlesIdx: [0, 6],
      activeAnswerId: null,
    };
  },

  computed: {
    styleBtnWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
        case 'lg':
        case 'md':
          return 100;
        case 'sm':
        case 'xs':
        default:
          return 40;
      }
    },
    styleIcon() {
      return [
        {
          icon: 'fal fa-angry',
          color: 'tt-red',
        },
        {
          icon: 'fal fa-frown-open',
          color: 'tt-orange',
        },
        {
          icon: 'fal fa-frown',
          color: 'tt-orange lighten-1',
        },
        {
          icon: 'fal fa-meh',
          color: 'tt-yellow',
        },
        {
          icon: 'fal fa-smile',
          color: 'tt-green lighten-2',
        },
        {
          icon: 'fal fa-laugh-beam',
          color: 'tt-green lighten-1',
        },
        {
          icon: 'fal fa-grin',
          color: 'tt-green',
        },
      ];
    },
  },

  created() {
    this.activeAnswerId = this.userAnswer.answerId;
  },

  methods: {
    isActive(answerId) {
      return answerId === this.activeAnswerId;
    },
    handleValue(id) {
      if (this.activeAnswerId === id) {
        this.activeAnswerId = null;
        this.$emit('delete-answer');
        return;
      }

      this.activeAnswerId = id;
      this.$emit('answer', {
        answerId: id,
      });
    },
    setSelectedClass(answerId) {
      if (!this.activeAnswerId) return '';
      return this.isActive(answerId) ? 'selected' : 'not-selected';
    },
    styleMarginsOnMobile(idx) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return {
          'mr-n12': idx === 0,
          'mx-n6': !this.defaultShownTitlesIdx.includes(idx),
          'ml-n12': idx === this.answers.length - 1,
        };
      }
      return '';
    },
    getBtnBackgroundColor(answerId, idx) {
      if (this.isActive(answerId)) return this.styleIcon[idx].color;
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.custom-btn-group-smile {
  line-height: 19px;
  letter-spacing: 0.61px;
  padding: 0;
  text-align: center;

  .v-btn {
    .v-btn__content {
      font-size: 20px;
      letter-spacing: -0.345455px;
      line-height: 25px;
      font-weight: 500;
    }

    &:hover:before {
      display: none;
    }
  }

  .subtitle {
    font-size: 14px;
    color: map-get($tt-black, 'lighten-2') !important;
  }
}

.custom-btn-group-smile.not-selected {
  .v-btn {
    .v-btn__content {
      .v-icon {
        color: map-get($tt-black, 'lighten-3') !important;
      }
    }
  }

  .subtitle {
    display: none;
  }
}

.custom-btn-group-smile.selected {
  .v-btn {
    .v-btn__content {
      .v-icon {
        color: map-get($tt-black, 'lighten-7') !important;
      }
    }
  }

  .subtitle {
    color: map-get($tt-black, 'base') !important;
  }
}
</style>
